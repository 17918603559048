/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import get from 'lodash/get';

const IconButton = ({ icon = null, type, xStyle = {}, color = 'brand-main', iconColor='brand-main', disabled = false, borderColor = null, border = false, backgroundColor = 'transparent', className='', ...props }) => {
	return (
		<button
			type={type ?? 'button'}
			disabled={disabled}
			className={`text-${color} bg-${backgroundColor} p-0 w-[32px] h-[32px] cursor-pointer ${border ? 'border rounded-2xl' : 'border-0'} border-solid border-${borderColor} ${className}`}
			{...props}
		>
			{icon ? <span className={`icon text-${iconColor} text-base`}>{icon}</span> : null}
		</button>
	);
};

export default IconButton;
