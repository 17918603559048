import { use } from 'chai';
import { useFormik } from 'formik';
import get from 'lodash/get';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';


type UseFormType = (any) => [any, (string) => any, {success: boolean, loading: boolean, errors: any, data: any, submitting: boolean, setErrors: (any) => void}]
const useForm:UseFormType = ({initialValues, validationSchema, mutation, onSuccess}) => {
    const router = useRouter();
    // const pathname = usePathname();
    const searchParams = useSearchParams();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [data, setData] = useState(initialValues);
    const [submitting, setSubmitting] = useState(false);
    const [autoSubmit, setAutoSubmit] = useState(false);
    // console.log("onSuccess", onSuccess)
    const doSuccess = useCallback(({newData}) => {
        // if(window != null) {
        //     const dataLayer = window.dataLayer || []
        //     dataLayer.push({
        //         event: 'formSubmit',
        //         type: pathname.split('/')[1] ?? 'general',
        //         form: pathname,
        //         success: true
        //     });
        // }
        if (typeof onSuccess === 'function') {
            // execute the callback passing the new data
            onSuccess(newData);
        } else if (typeof onSuccess === 'string') {
            // redirect to the given url
            router.push(onSuccess);
        }
    }, [onSuccess, router]);

    const onSubmit = useCallback(
        async values => {
            // console.log('onSubmit', values);
            setSubmitting(true);
            setErrors(null);
            try {
                const res = await mutation(values);
                const { data = null, success, errors } = res;
                setSuccess(success);
                setSubmitting(false);
                if (success !== false && data != null) {
                    setData(data);
                    doSuccess({ newData: data });
                } else {
                    setErrors([...(errors ?? [])]);
                    console.error('Error: wrong payload', success, data, errors);
                }
            } catch (error) {
                setSuccess(false);
                // setData(null);
                setErrors([error.message]);
                setSubmitting(false);
            }
        },
        [mutation, doSuccess],
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit,
    });

    const getFieldProps = field => {
        const fieldName = typeof field === 'string' ? field : field.name;
        return {
            ...formik.getFieldProps(field),
            setValue: (v, opt) => {

                formik.setFieldValue(fieldName, v, true).then(res => {
                    if (opt && opt.submit) {
                        formik.submitForm();
                    }
                });
                formik.setFieldValue(fieldName, v, true).then(() => {
                    formik.validateField(fieldName);
                });

            },
            setTouched: v => {
                formik.setFieldTouched(fieldName, v);
            },
            initialValue: get(formik.initialValues, fieldName, null),
            state: get(formik.touched, fieldName, false) && get(formik.errors, fieldName, false) ? 'error' : null,
            message: get(formik.touched, fieldName, null) ? get(formik.errors, fieldName, null) : null,
            submitForm: () => { 
                formik.submitForm()
            },
        }
    }

    useEffect(() => {
        const queryValues = searchParams;
        queryValues.forEach((value, key) => {
            if (key.startsWith('utm_')) {
                if(formik.values[key] !== value) {
                    console.log('setting', key, value);
                    formik.setFieldValue(key, value);
                }
            }
        });
    }, [formik, searchParams]);

    return [
        formik, 
        getFieldProps, 
        { success, loading, errors, data, submitting, setErrors }];
};

export default useForm;
