'use client';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { CheckButton } from '../Buttons/Button.old';
import { useSelect } from 'downshift';
import { useIMask } from 'react-imask';
import InputMessage from './InputMessage';
import IconButton from '../Buttons/IconButton';
import Icon from '../Icon';
import Typography from '../Typography';
import deprecationWarning from '../Utils/deprecationWarning';

export const DefaultInput = ({
  type = 'text',
  autoComplete,
  id,
  name = id,
  multiline = false,
  lines = 3,
  placeholder = '',
  value = '',
  state,
  maskOptions = {},
  xStyle = {},
  onChange = () => { },
  onBlur = () => { },
  setValue,
  disabled = false,
  hasLeftIcon = false,
  ...props
}) => {
  const [opts, setOpts] = useState({ ...maskOptions });
  const { ref, maskRef } = useIMask(opts);

  useEffect(() => { setOpts({ ...maskOptions }) }, [maskOptions])
  useEffect(() => {
    if (value != null && typeof value !== 'string') {
      setValue(value.toString());
    }
    if (typeof value === 'string' && maskRef.current && value !== maskRef.current.value) {
      maskRef.current.value = value;
      maskRef.current.updateValue();
    }
  }, [value, maskRef.current]);


  return jsx(
    multiline ? 'textarea' : 'input',
    {
      ...props,
      rows: multiline ? lines : null,
      ref: maskOptions ? ref : null,
      placeholder,
      type,
      autoComplete,
      id,
      name,
      disabled,
      value: maskRef.current?.value ? maskRef.current?.value : value,
      onChange,
      onBlur,
      onInput: maskRef.current ? () => setValue(maskRef.current.value) : null,
      error: state === 'error' ? 'true' : undefined,
      css: (theme) => ({
        height: multiline ? 'auto' : '5rem',
        width: '100%',
        minWidth: '291px',
        // maxWidth: '604px',
        paddingTop: multiline ? '1.6rem' : 0,
        paddingBottom: multiline ? '1.6rem' : 0,
        paddingLeft: hasLeftIcon ? 82 : '1.6rem',
        paddingRight: '1.6rem',
        borderRadius: '10px',
        border: `1px solid ${theme.colors.base['grey-5']}`,
        color: theme.colors.base['grey-5'],
        backgroundColor: 'transparent',
        ...theme.typography.input.field,
        '&:hover': {
          border: `2px solid ${theme.colors.brand.main}`,
          padding: multiline ? '1.5rem' : '0 1.5rem',
          paddingLeft: hasLeftIcon ? 81 : '1.5rem',
        },
        '&:focus': {
          border: `2px solid ${theme.colors.brand.main}`,
          backgroundColor: theme.colors.brand.light,
          outline: 'none',
          padding: multiline ? '1.5rem' : '0 1.5rem',
          paddingLeft: hasLeftIcon ? 81 : '1.5rem',
        },
        '&[error]': {
          border: `2px solid ${theme.colors.error.dark}`,
          backgroundColor: theme.colors.error.light,
          padding: multiline ? '1.5rem' : '0 1.5rem',
          paddingLeft: hasLeftIcon ? 81 : '1.5rem',
        },
        ...props.css,
        ...typeof xStyle === 'function' ? xStyle(theme) : xStyle
      }),
    },
    null,
  );
};

export const DefaultLabel = ({ htmlFor, required = true, suffix = null, children = null, ...props }) =>
  jsx(
    'label',
    {
      ...props,
      htmlFor,
      css: theme => ({
        ...theme.typography.input.label,
        position: 'relative',
        ...props.css,
      }),
    },
    children,
    required ? null : <span className="optional">(opcional)</span>,
    suffix,
  );

export const InputField = ({
  id,
  name,
  type,
  autoComplete,
  state,
  placeholder,
  label,
  labelMessage,
  value,
  onChange,
  onBlur,
  message,
  required = true,
  multiline = false,
  lines = 3,
  maskOptions = {},
  disabled,
  setValue,
  labelProps = {},
  inputProps = {},
  messageProps = {},
  xStyle = {},
  leftIcon = null,
  title,
}) => {
  return (
    <div
      css={theme => ({
        position: 'relative',
        marginTop: '3rem',
        marginBottom: '3rem',
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        ...(typeof xStyle === 'function' ? xStyle(theme) : xStyle),
      })}
    >
      {label != null ? <DefaultLabel htmlFor={id} {...labelProps} required={required}>
        {labelMessage ? <div>{label} <Typography component="div" variant="body3" xStyle={{ marginBottom: 0 }}>{labelMessage}</Typography></div> : label}
      </DefaultLabel> : null}
      {leftIcon == null
        ? <DefaultInput
          id={id}
          name={name}
          type={type}
          multiline={multiline}
          lines={lines}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          state={state}
          maskOptions={maskOptions}
          disabled={disabled}
          setValue={setValue}
          title={title}
          {...inputProps} />
        : <div css={{
          display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
          '&>i': {
            marginLeft: 32,
            marginRight: -54,
          }
        }}>
          {leftIcon}
          <DefaultInput
            id={id}
            name={name}
            type={type}
            multiline={multiline}
            lines={lines}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            state={state}
            maskOptions={maskOptions}
            disabled={disabled}
            setValue={setValue}
            hasLeftIcon={true}
            title={title}
            {...inputProps} /></div>
      }

      <InputMessage state={state} {...messageProps}>
        {message}
      </InputMessage>
    </div>
  );
};

export const InputPassword = ({
  id,
  name,
  type,
  autoComplete,
  state,
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  message,
  required = true,
  multiline = false,
  lines = 3,
  maskOptions = {},
  disabled,
  setValue,
  labelProps = {},
  inputProps = {},
  messageProps = {},
  xStyle = {},
  leftIcon = null,
}) => {

  const [hiddenPassword, setHiddenPassword] = useState(true);
  const toggleHidden = () => setHiddenPassword(!hiddenPassword);

  return (
    <div
      css={theme => ({
        position: 'relative',
        marginTop: '3.6rem',
        marginBottom: '3.6rem',
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        ...(typeof xStyle === 'function' ? xStyle(theme) : xStyle),
      })}
    >
      {label != null ? <DefaultLabel htmlFor={id} {...labelProps} required={required}>
        {label}
      </DefaultLabel> : null}
      {leftIcon == null
        ? <DefaultInput
          id={id}
          name={name}
          type={hiddenPassword ? type : 'text'}
          multiline={multiline}
          lines={lines}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          state={state}
          maskOptions={maskOptions}
          disabled={disabled}
          setValue={setValue}
          {...inputProps} />
        : <div css={{
          display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
          '&>i': {
            marginLeft: 32,
            marginRight: -54,
          }
        }}>
          {leftIcon}
          <DefaultInput
            id={id}
            name={name}
            type={hiddenPassword ? type : 'text'}
            multiline={multiline}
            lines={lines}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            state={state}
            maskOptions={maskOptions}
            disabled={disabled}
            setValue={setValue}
            hasLeftIcon={true}
            {...inputProps} />
          {hiddenPassword ? (
            <IconButton
              onClick={toggleHidden}
              className='absolute right-6'
              icon={
                <Icon className="fi fi-br-eye text-base-grey-4 text-2xl"></Icon>}
              />
          ) :
            <IconButton
              onClick={toggleHidden}
              className='absolute right-6'
              icon={
                <Icon className="fi fi-br-eye-crossed  text-base-grey-4 text-2xl"></Icon>}
              />
          }
        </div>
      }

      <InputMessage state={state} {...messageProps}>
        {message}
      </InputMessage>
    </div>
  );
};

const selectStyles = (theme, { isOpen, disabled, hasLeftIcon, xStyle }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '5rem',
  width: '100%',
  minWidth: '291px',
  cursor: 'pointer',
  // maxWidth: '604px',
  padding: '0 1.6rem',
  paddingLeft: hasLeftIcon ? 82 : '1.6rem',
  borderRadius: isOpen ? '10px 10px 0 0' : '10px',
  border: `1px solid ${theme.colors.base['grey-5']}`,
  backgroundColor: disabled ? theme.colors.base['grey-3'] : 'transparent',
  textAlign: 'left',
  ...theme.typography.input.field,
  opacity: disabled ? 0.7 : 1,
  color: disabled ? theme.colors.base['grey-4'] : theme.colors.base['grey-5'],
  cursor: disabled ? 'not-allowed' : 'pointer',

  '&:hover:not(:disabled)': {
    border: `2px solid ${theme.colors.brand.main}`,
    padding: '0 1.5rem',
    paddingLeft: hasLeftIcon ? 81 : '1.5rem',
  },
  '&:focus:not(:disabled)': {
    border: `2px solid ${theme.colors.brand.main}`,
    backgroundColor: theme.colors.brand.superlight,
    outline: 'none',
    padding: '0 1.5rem',
    paddingLeft: hasLeftIcon ? 81 : '1.5rem',
  },
  '&[error]:not(:disabled)': {
    border: `2px solid ${theme.colors.error.dark}`,
    backgroundColor: theme.colors.error.light,
    padding: '0 1.5rem',
    paddingLeft: hasLeftIcon ? 81 : '1.5rem',
  },
  i: {
    color: disabled ? theme.colors.base['grey-4'] : theme.colors.brand.main,
    marginTop: 4,
  },
  ...typeof xStyle === 'function' ? xStyle(theme) : xStyle,
});

export const SelectField = forwardRef(({
  id,
  name,
  state,
  placeholder,
  label,
  initialValue = '',
  message,
  required = true,
  setValue = () => { },
  onBlur = () => { },
  options = [],
  labelProps: {
    xStyle: labelXStyle,
    ...labelProps
  } = {},
  inputProps: {
    xStyle: inputXStyle,
    ...inputProps
  } = {},
  messageProps = {},
  xStyle = {},
  setTouched = () => { },
  leftIcon = null,
  disabled = false,
  t = str => str,
}, ref) => {
  deprecationWarning('SelectField', 'this component is deprecated and will be removed in a future version. Please use the SelectField.tsx component instead.');

  const [selected, setSelected] = useState(initialValue);
  const [touchedState, setTouchedState] = useState(false);
  const handleSelectedItemChange = ({ selectedItem }) => {
    setSelected(selectedItem);
    setValue(selectedItem.value);
    setTouched(true);
  };
  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    id: id ?? name,
    items: options,
    selectedItem: selected,
    onSelectedItemChange: handleSelectedItemChange,
  });

  useEffect(() => {
    if (initialValue !== '') {
      const selected = options.findIndex(option => option.value === initialValue);
      if (selected > -1) {
        setSelected(options[selected]);
      }
    }
  }, [initialValue]);

  useEffect(() => {
    if (isOpen === true) {
      setTouchedState(true);
    } else if (touchedState === true) {
      setTouched(true);
    }
  }, [isOpen, selected]);

  useImperativeHandle(ref, () => ({
    setSelectedValue: (val) => {
      setSelected({ value: val, label: val });
    }
  }));

  return (
    <div css={theme => ({ position: 'relative', marginTop: '3.6rem', marginBottom: '3.6rem', opacity: disabled ? 0.5 : 1, ...(typeof xStyle === 'function' ? xStyle(theme) : xStyle) })}>
      {label ? <DefaultLabel {...getLabelProps()} {...labelProps} required={required}>
        {label}
      </DefaultLabel> : null}

      {leftIcon == null
        ? <button type="button" disabled={disabled} css={
          theme => selectStyles(theme, { isOpen, disabled, xStyle: inputXStyle })
        } {...getToggleButtonProps()} error={state === 'error' ? 'true' : undefined} {...inputProps}>
          <span>{
            selected?.label && selected.label != ''
              ? selected?.label
              : (placeholder ?? 'Selecione...')}</span>
          <i className="fi fi-br-angle-down"></i>
        </button>
        : <div css={{
          display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
          '&>i': {
            marginLeft: 32,
            marginRight: -54,
          }
        }}>
          {leftIcon}
          <button type="button" disabled={disabled} css={
            theme => selectStyles(theme, { isOpen, disabled, hasLeftIcon: true, xStyle: inputXStyle })
          } {...getToggleButtonProps()} error={state === 'error' ? 'true' : undefined} {...inputProps}>
            <span>{
              selected?.label && selected.label != ''
                ? selected?.label
                : (placeholder ?? 'Selecione...')}</span>
            <i className="fi fi-br-angle-down"></i>
          </button>
        </div>}
      <ul
        {...getMenuProps()}
        css={({ typography, colors }) => ({
          display: isOpen ? 'block' : 'none',
          ...typography.body3,
          border: '1px solid',
          borderColor: colors.base['grey-5'],
          maxHeight: 200,
          width: '100%',
          overflowY: 'scroll',
          backgroundColor: 'white',
          padding: 0,
          listStyle: 'none',
          position: 'absolute',
          borderBottomLeftRadius: '1rem',
          borderBottomRightRadius: '1rem',
          zIndex: 1000,
        })}
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              css={({ colors }) => ({
                height: 49,
                backgroundColor: highlightedIndex === index ? colors.brand.superlight : '',
                fontWeight: highlightedIndex === index ? 700 : 400,
                padding: '0 1.6rem',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              })}
              key={`${item.value}${index}`}
              {...getItemProps({ item: item.value, index })}
            >
              {item.label}
            </li>
          ))}
      </ul>
      <InputMessage state={state} {...messageProps}>
        {message}
      </InputMessage>
    </div>
  );
});

export const Check = ({ id, name, checked, value, disabled, children, state, message, labelProps = {}, xStyle = {}, onChange, messageProps = {}, checkBoxSize = 'medium', ...props }) => {
  return (
    <div css={{ marginTop: '3.6rem', marginBottom: '3.6rem', ...xStyle }}>
      <div css={css({ display: 'flex', alignItems: 'flex-start' })}>
        <CheckButton id={id} name={name} checked={checked} value={true} disabled={disabled} variant={checkBoxSize} onChange={onChange}></CheckButton>
        <label {...labelProps} css={css({ marginLeft: 16, ...labelProps.css })}>
          {children}
        </label>
      </div>
      <InputMessage state={state} {...messageProps}>
        {message}
      </InputMessage>
    </div>
  );
};
