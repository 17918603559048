'use client';
/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { jsx } from '@emotion/react';
import Typography from '../Typography';
import Icon from '../Icon';

const makeBtnSize = (variant, breakpoints, options) => {
  if (variant == null) return ``;
  let values;
  if (variant === 'xs') {
    values = {
      height: '3.5rem',
      fontSize: '1.4rem',
      minWidth: '291px',
      padding: '1.2rem 3rem',
      borderRadius: '5rem',
    };
  } else if (variant === 'small') {
    values = {
      height: '4.4rem',
      fontSize: '1.6rem',
      minWidth: '291px',
      padding: '1.2rem 3rem',
      borderRadius: '5rem',
    };
  } else if (variant === 'medium') {
    values = {
      height: '5rem',
      fontSize: '1.8rem',
      minWidth: '291px',
      padding: '1.3rem 2rem',
      borderRadius: '5rem',
    };
  } else if (variant === 'large') {
    values = {
      height: '5.5rem',
      fontSize: '1.8rem',
      minWidth: '291px',
      padding: '1.5rem 4rem',
      borderRadius: '5rem',
      smallPadding: '1.5rem 2rem',
    };
  } else if (variant === 'xxl') {
    values = {
      height: '9.5rem',
      fontSize: '2.6rem',
      padding: '2rem',
      borderRadius: '5rem',
    };
  } else {
    values = {
      height: '7.5rem',
      fontSize: '2.6rem',
      maxWidth: '448px',
      padding: '2rem 6rem',
      borderRadius: '5rem',
      smallPadding: '2rem 3rem',
    };
  }

  return `
		height: ${values.height};
		font-size: ${values.fontSize};
		min-width: ${options?.square ? 'auto' : values.minWidth};
		max-width: ${values.maxWidth};
		padding: ${options?.square ? '0' : values.padding};
		border-radius: ${options?.noRadius ? '1rem' : values.borderRadius};

		width: ${options?.square ? values.height : ''};
		transition: color .3s, background-color .3s, border-color .5s;

		${[breakpoints.down('md')]} {
			padding: ${values.smallPadding};
		}
	`;
};

export const DefaultButton = styled.button`
	${({ variant = 'large', theme = {} }) => makeBtnSize(variant, theme.breakpoints)};
	font-weight: 700;
	cursor: pointer;
	&:disabled {
		cursor: no-drop;
		background-color: ${({ theme }) => theme.colors.base['grey-3']};
	}

	&:focus:not(:disabled) {
		outline: 4px solid #091ae080;
		outline-offset: 4px;
	}

	.icon {
		margin-right: 3rem;
		display: inline-block;
		font-size: 133.33%;
		line-height: 1rem;
		i:before {
			vertical-align: middle;
		}
	}
`;

export const PrimaryButtonStyled = styled(DefaultButton)`
	color: white;
	background-color: ${({ theme }) => theme.colors.brand.main};
	border: none;

	&:disabled {
	}

	&:hover:not(:disabled) {
		background-color: ${({ theme }) => theme.colors.brand.dark};
	}
`;

export const SecondaryButtonStyled = styled(DefaultButton)`
	color: ${({ theme }) => theme.colors.brand.main};
	background-color: transparent;
	border: 1px solid currentColor;

	&:disabled {
	}

	&:hover:not(:disabled) {
		color: white;
		background-color: ${({ theme }) => theme.colors.brand.main};
		border-color: transparent;
	}
`;
export const TertiaryButtonStyled = styled.button`
	color: ${({ theme }) => theme.colors.brand.main};
	padding: 1.2rem;
	min-width: auto;
	background-color: transparent;
	border: none;
	display: flex;
	font-weight: 700;
	cursor: pointer;
	&:hover:not(:disabled) {
		border-color: transparent;
	}
	.icon {
		margin-right: 1.2rem;
		display: inline-block;
		font-size: 133.33%;
		line-height: 1rem;
		i:before {
			vertical-align: middle;
		}
	}
`;

export const PrimaryButton = ({ icon = null, children = '', variant = 'large', xStyle = {}, disabled = false, ...props }) => {
  return (
    <PrimaryButtonStyled variant={variant} disabled={disabled} css={xStyle} {...props}>
      {icon ? <span className="icon">{icon}</span> : null}
      {children}
    </PrimaryButtonStyled>
  );
};

export const SecondaryButton = ({ icon = null, children = '', variant = 'large', xStyle = {}, disabled = false, ...props }) => {
  return (
    <SecondaryButtonStyled variant={variant} disabled={disabled} css={xStyle} {...props}>
      {icon ? <span className="icon">{icon}</span> : null}
      {children}
    </SecondaryButtonStyled>
  );
};

export const TertiaryButton = ({ icon = null, children = '', variant = 'large', xStyle = {}, disabled = false, ...props }) => {
  return (
    <TertiaryButtonStyled variant={variant} disabled={disabled} css={xStyle} {...props}>
      {icon ? <span className="icon">{icon}</span> : null}
      {children}
    </TertiaryButtonStyled>
  );
};

export const OptionButtonStyled = styled.span`
	display: inline-block;
	input {
		position: absolute;
		opacity: 0;
		width: 1px;
		height: 1px;
		margin-right: -5px;
	}

	label {
		${({ variant = 'large', theme = {} }) => makeBtnSize(variant, theme.breakpoints, { noRadius: true })};
		padding-left: 30px;
		padding-right: 30px;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.base['grey-5']};
		border: 1px solid currentColor;
		border-width: ${props => (props.variant === 'xxl' ? '2px' : '1px')};
		.icon {
			margin-right: 16px;
			display: inline-block;
			font-size: 133.33%;
			line-height: 1rem;
			i:before {
				vertical-align: middle;
			}
		}
		${({ theme, checked }) =>
    checked
      ? `
			color: ${theme.colors.brand.main};
			background-color: ${theme.colors.brand.light};
			border-width: 2px;
			font-weight: 700;
		`
      : ''}
	}

	input + label:hover {
		color: white;
		background-color: ${({ theme }) => theme.colors.brand.main};
		border-color: transparent;
	}

	input:focus + label,
	label:focus {
		font-weight: 700;
		outline: 4px solid #091ae080;
		outline-offset: 4px;
	}
`;

export const OptionButton = ({ children = '', name, id = name, value, disabled = false, checked = false, xStyle, onChange = null, ...props }) => {
  return (
    <OptionButtonStyled css={xStyle} checked={checked} {...props}>
      <input type="checkbox" checked={checked} id={id} name={name} value={value} onChange={onChange} />
      <label htmlFor={id} checked>
        {checked ? (
          <span className="icon">
            <Icon className="fi fi-br-check" />
          </span>
        ) : null}
        {children}
      </label>
    </OptionButtonStyled>
  );
};

export const CheckButtonStyled = styled(OptionButtonStyled)`
	label {
		${({ variant = 'large', theme = {} }) => makeBtnSize(variant, theme.breakpoints, { square: true, noRadius: true })};
		justify-content: center;
		.icon {
			margin-right: 0;
			display: inline-block;
			font-size: 133.33%;
			line-height: 1rem;
			i:before {
				vertical-align: middle;
			}
		}
		&:hover {
			color: white;
			background-color: ${({ theme }) => theme.colors.brand.main};
			border-color: transparent;
			box-shadow: inset 0 0 0 3px white;
		}
	}
	input:checked + label {
	}
`;

export const CheckButton = ({
  type = 'checkbox',
  icon = null,
  alwaysShowIcon = false,
  variant = 'large',
  checkBoxSize = 'medium',
  name,
  id = name,
  value,
  xStyle,
  disabled,
  title,
  checked = false,
  onChange = null,
  ...props
}) => {
  return (
    <CheckButtonStyled variant={variant} css={xStyle} checked={checked} checkBoxSize={checkBoxSize} {...props}>
      <input type={type} checked={checked} id={id} name={name} value={value} disabled={disabled} onChange={onChange} />
      <label htmlFor={id} checked={checked}>
        {checked || alwaysShowIcon ? <span className="icon">{icon ?? <i className="fi fi-br-check"></i>}</span> : null}
      </label>
      {title ? (
        <Typography variant="body1" xStyle={{ textAlign: 'center', marginTop: 14 }}>
          {title}
        </Typography>
      ) : null}
    </CheckButtonStyled>
  );
};

export const AddButton = ({ xStyle = {}, children, ...props }) =>
  jsx(
    'button',
    {
      ...props,
      css: theme => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.6rem',
        lineHeight: 1,
        height: '6.2rem',
        padding: '0 1.8rem',
        backgroundColor: 'white',
        borderRadius: '1rem',
        color: theme.colors.brand.dark,
        cursor: 'pointer',
        border: `2px dashed ${theme.colors.base['grey-4']}`,
        transition: 'background-color 0.4s',
        '&:focus': {
          outline: '4px solid #091AE080',
          outlineOffset: '4px',
        },
        '&:hover': {
          fontWeight: 700,
          borderColor: 'transparent',
          color: 'white',
          backgroundColor: theme.colors.brand.main,
          '.icon': {
            color: 'white',
          },
        },
        '.icon': {
          marginRight: '1rem',
          display: 'inline-block',
          fontSize: '133.33%',
          color: theme.colors.brand.main,
        },
        ...xStyle,
      }),
    },
    <span className="icon">
      <Icon icon="add-circle" />
    </span>,
    <span>{children}</span>,
  );
